<template>
    <div>
        <textarea class="text-report" placeholder="Provide clarification on the issue you experienced..." name="fail-report" id="fail-report" rows="3"></textarea>
        <div class="notify-slack">
            <div class="notify-slack-right">
                <input class="notify-checkbox" type="checkbox">
                <div class="notify-message">notify the dev team about this issue on slack</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.text-report {
    margin: 9px 0 3px 0;
    padding: 2px 6px;
    background-color: rgb(238, 238, 238);
    font-size: 18px;
    border-radius: 4px;
    resize: none;
    display: block;
    width: 100%;
}

.notify-slack {
    display: flow-root;
}

.notify-slack-right {
    float: right;
}

.notify-checkbox {
    position: relative;
    top: 5px;
    width: 20px;
    height: 20px;
}

.notify-message {
    display: inline;
    margin: 0 6px;
    font-size: 16px;
}
</style>