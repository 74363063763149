<template>
  <div class="qa">
    <div class="container">
      <h4 style="float: right">
        <button class="start-button">
          <b-icon icon="clock-history">
          </b-icon> 
          <a :href="'/history'"> History</a>
        </button>
        <button class="start-button" v-if="editor" @click="startQASession">
          <b-icon icon="card-checklist">
          </b-icon> Start QA
        </button>
        <button class="start-button" v-if="!editor" @click="editorMode">
          <b-icon icon="card-checklist">
          </b-icon> 
          Abort Session
        </button>
      </h4>

      <h4 v-if="editor"> {{selectedService.name}}: Editor Mode </h4>
      <h4 v-if="!editor">{{ selectedService.name }}: QA Mode </h4>
     <hr />
    </div>
    <Toolbar
    :dashboardSectors="dashboardSectors"
    :selectedService="selectedService" 
    @service-change="setSelectedService"
    />
    <keep-alive>
      <TestCardsArea
      :dashboardSectors="dashboardSectors"
      :selectedService="selectedService"
      :changeSwitch="changeSwitch"
      :editor="editor"
      />
    </keep-alive>
    <div>
        <button class="add-category" v-b-modal="'modal-add-cat'" v-if="editor">
          <img class="add-test-button" src="../assets/plus-circle.svg" alt="">
          Add a new category.
        </button>

        <b-modal id="modal-add-cat" title="Create a new category" hide-footer>
          Section: {{ this.selectedService.name }}
            <b-form @submit.prevent="closeModalCreateCategory('modal-add-cat')">
                <b-form-group
                    id="input-group-1"
                    label="Category Title:"
                    label-for="input-1"
                >
                    <b-form-input
                    id="input-1"
                    v-model="categoryTitle"
                    type="string"
                    onFocus="this.value=''"
                    placeholder="Please enter a new Category Title"
                    required
                    ></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary">Submit</b-button>
            </b-form>
        </b-modal>

        <button v-if="selectedService.id === 7 && !editor" style="float: right" type="button" @click="submitForm" class="btn btn-dark">Submit</button>
        <button v-else-if="selectedService.id != 7" style="float: right" type="button" @click="nextService" class="btn btn-dark">next dashboard >></button>
    </div>
  </div>
</template>

<script>

import TestCardsArea from '../components/QA/TestCardsArea.vue'
import Toolbar from '../components/QA/Toolbar.vue'
import RequestService from '../repositories/QARepository'

export default {
  components: {
    Toolbar, 
    TestCardsArea
  },
  data: () => ({  
    editor: true,
    changeSwitch: true,
    selectedService: null,
    categoryTitle: null,
    dashboardSectors: [
      {
        id: 0,
        name: "Website",
        desc: "",
        img: require("@/assets/website.png"),
        categories: [],
        glow: '#DF3D2A',
      },
      {  
        id: 1,
        name: "Dashboard",
        desc: "",
        img: require("@/assets/dashboard.png"),
        categories: [],
        glow: 'yellow',
      },
      { 
        id: 2,
        name: "Operator",
        desc: "",
        img: require("@/assets/operator.png"),
        categories: [],
        glow: '#259DE8',
      },
      { 
        id: 3,
        name: "Organizations",
        desc: "",
        img: require("@/assets/organization.png"),
        categories: [],
        glow: '#149b41',
      },
      { 
        id: 4,
        name: "Commuter",
        desc: "",
        img: require("@/assets/commuter.png"),
        categories: [],
        glow: 'black',
      },
      { 
        id: 5,
        name: "Delivery",
        desc: "",
        img: require("@/assets/delivery.png"),
        categories: [],
        glow: '#259DE8',
      },
      { 
        id: 6,
        name: "Android",
        desc: "",
        img: require("@/assets/playstore.png"),
        categories: [],
        glow: 'purple',
      },
      {
        id: 7,
        name: "IOS",
        desc: "",
        img: require("@/assets/applestore.png"),
        categories: [],
        glow: '#259DE8',
      },
    ] 
  }),
  created () {
    this.selectedService = this.dashboardSectors[0]
  },
  methods: {
    startQASession() {
      if (confirm("You're starting the QA Session.\nPlease make sure to complete the session before submitting.\nIf you close the session before submitting all your progress will be lost.\nClick 'OK' to start..."))
        this.editor = false
    },
    editorMode() {
      if (confirm("You're about to abort the QA session.\nYour data will be discarded!\nClick 'OK' to abort..."))  
        this.editor = true
    },
    setSelectedService(id){
      this.selectedService = this.dashboardSectors[id]
      console.log(this.selectedService)
      window.scrollTo(0,0)
    },  
    nextService() {
      this.selectedService = this.dashboardSectors[(this.selectedService.id)+1]
      window.scrollTo(0,0)
    },
    closeModalCreateCategory(modalID) {
      this.createCategory()
      this.$bvModal.hide(modalID)
    },
    async createCategory() {
      const category = await RequestService.createQACategory({
        'service': this.selectedService.name,
        'title': this.categoryTitle,
      })
      console.log(category);
      this.changeSwitch = !this.changeSwitch
    },
    allTestsFilled() {
      var testsNotFilled = ""
      this.dashboardSectors.forEach((sector) => {
        sector.categories.forEach((category) => {
          category.tests.forEach((test) => {
            if (test.pass == null) {
              testsNotFilled = testsNotFilled + sector.name + ' -> ' + category.title + ' -> ' + test.test_case + '\n'
            }
          })
        })
        if (sector.categories.length === 0) {
          testsNotFilled = testsNotFilled + sector.name + ' not visited.\n'
        }
      });
      return testsNotFilled
    },
    async submitForm() {
      const testsNotFilled = this.allTestsFilled()
      if (testsNotFilled === "") {
        let passCount = 0;
        let failCount = 0;
        
        this.dashboardSectors.forEach((s) => {
          s.categories.forEach((c)=>{
            c.tests.forEach((test)=>{
              if (test.pass) {
                passCount++;
              } else {
                failCount++;
              }
            })
          })
        })
        RequestService.createQAHistory({
          pass_count: passCount,
          fail_count: failCount,
        })
        confirm('Submitted successfully!')
      } else {
        confirm('The following tests have not been filled in:\n'+testsNotFilled+'Please go back and fill them in.')
      }
    },
  },

}

</script>

<style scoped>
.qa {
  margin-bottom: 100px;
}

.container, .navbar {
  padding: 0px;
}

.start-button {
  border-radius: 10px;
  background-color: transparent;
  border: none !important;
  padding: 5px 20px;
  font-size: 20px;
}

.start-button:hover {
  background-color: #47B17D;
  color: white;
}

.add-category {
  padding: 1%;
  background: transparent;
  border: none !important;
  font-size: 20px;
}

.add-test-button {
  width: 19px;
  position: relative;
  top: -2px;
}
</style>