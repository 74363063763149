<template>
    <div class="whole">
        <div class="full-card-area" v-for="card in selectedService.categories" :key="card.title">
            <SingleCard :card-data="card" :editor="editor" @delete-category="getCategories" />
        </div>
    </div>
</template>

<script>
import SingleCard from './SingleCard.vue'
import RequestService from '../../repositories/QARepository'

export default {
    components: {
        SingleCard
    },
    props: [
        'dashboardSectors',
        'selectedService',
        'changeSwitch',
        'editor'
    ],
    created () {
        this.getCategories()
    },
    watch: {
        selectedService: function () {
            if (this.selectedService.categories.length === 0) {
                this.getCategories()
            }
        },
        changeSwitch: function () {
            this.getCategories()
        }
    },
    methods: {
        async getCategories() {
            const categories = await RequestService.getQACategories({
                'service': this.selectedService.name
            })
            var newCards = []
            categories.data.forEach((category) => {
                newCards.push({
                    'id': category.id,
                    'service': category.service,
                    'title': category.title,
                    'tests': []
                })
            });
            this.dashboardSectors.forEach((sector) => {
                if (sector.name === this.selectedService.name) {
                    sector.categories = newCards
                }
            });
        }
    }
}
</script>