<template>
    <div>
        <h2 class="category">Category: <span class="card-cat">{{ cardData.title }}</span></h2>
        <button class="float-right invisible-button del-cat" v-b-modal="modalId('-del-cat-' + cardData.id)" v-if="editor">
            delete category
            <img class="trash" src="../../assets/red-trash.svg" alt="trash">
        </button>

        <b-modal
        :id="'modal-del-cat-' + cardData.id"
        title="Confirm delete."
        hide-footer>
            The following category will be deleted:<br>
            <span class="red-text">{{ cardData.title }}</span><br><br>
            <b-form @submit.prevent="closeModalDeleteCategory('modal-del-cat-', cardData.id)">
                <b-button
                class="red-button"
                type="submit"
                variant="primary"
                >Delete</b-button>
            </b-form>
        </b-modal>

        <div class="card-area">
            <h3>Test Cases:</h3>
            <div class="single-test" v-for="(test, index) in cardData.tests" :key="test.test_case">
                <div class="test-description">{{ index + 1 }}. {{ test.test_case }}</div>
                <div class="float-right">
                    <form v-if="!editor">
                        <label class="radio-buttons">
                            <input type="radio" class="radioStatus" name="radioStatus" value="pass" @click="setPass(index, true)">
                            pass
                        </label>
                        <label class="radio-buttons">
                            <input type="radio" class="radioStatus" name="radioStatus" value="fail" @click="setPass(index, false)">
                            fail
                        </label>
                    </form>
                    
                    <button class="trash-button invisible-button" v-b-modal="modalId('-del-test-' + test.id)" v-if="editor">
                        <img class="trash" src="../../assets/trash.svg" alt="trash">
                    </button>

                    <b-modal
                    :id="'modal-del-test-' + test.id"
                    title="Confirm delete."
                    hide-footer>
                        The following test will be deleted:<br>
                        <span class="red-text">{{ cardData.title }} -> {{ test.test_case }}</span><br><br>
                        <b-form @submit.prevent="closeModalDeleteTest('modal-del-test-', test.id)">
                            <b-button
                            class="red-button"
                            type="submit"
                            variant="primary"
                            >Delete</b-button>
                        </b-form>
                    </b-modal>

                </div>
                <FailReport class="fail-report" v-if="test.pass === false" />
            </div>
            
            <button  class="invisible-button" v-b-modal="modalId('-add-test-' + cardData.id)" v-if="editor">
                <img class="add-test-button" src="../../assets/plus-circle.svg" alt="add test">
                Add a new test case.
            </button>

            <b-modal
            :id="'modal-add-test-' + cardData.id"
            title="Add a new test case"
            hide-footer>
                Category: {{ cardData.title }}

                <b-form @submit.prevent="closeModalCreateTest('modal-add-test-', cardData.id)">
                    <b-form-group
                        id="input-group-1"
                        label="Test Title:"
                        label-for="input-1"
                    >
                        <b-form-input
                        id="input-1"
                        v-model="testTitle"
                        type="string"
                        onFocus="this.value=''"
                        placeholder="Please enter a new Test Title"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Submit</b-button>
                </b-form>
            </b-modal>

        </div>
    </div>
</template>

<script>
import FailReport from "./FailReport.vue"
import RequestService from '../../repositories/QARepository'

export default {
    components: {
        FailReport
    },
    props: [
        'cardData',
        'editor'
    ],
    watch: {
        cardData: function () {
            this.getTestCases()
        }
    },
    created () {
        this.getTestCases()
    },
    methods: {
        setPass(id, status) {
            this.cardData.tests[id].pass = status
        },
        modalId(categoryAndOrName) {
            return 'modal' + categoryAndOrName
        },
        closeModalDeleteCategory(modalPrefix, id) {
            this.deleteCategory(id)
            this.$bvModal.hide(modalPrefix + id)
        },
         async deleteCategory(categoryID) {
            const category = await RequestService.deleteCategory({
                'id': categoryID,
            })
            console.log(category);
            this.$emit('delete-category')
        },
        async getTestCases() {
            const questions = await RequestService.getQATestCases({
                'category_id': this.cardData.id,
            })
            questions.data.forEach((testObject) => {
                testObject.pass = null
            })
            this.cardData.tests = questions.data
        },
        closeModalCreateTest(modalPrefix, id) {
            this.createTestCase()
            this.$bvModal.hide(modalPrefix + id)
        },
        async createTestCase() {
            const testCase = await RequestService.createTestCase({
                'test_case': this.testTitle,
                'category_id': this.cardData.id,
            })
            console.log(testCase)
            this.getTestCases()
        },
        closeModalDeleteTest(modalPrefix, id) {
            this.deleteTestCase(id)
            this.$bvModal.hide(modalPrefix + id)
        },
        async deleteTestCase(testCaseID) {
            const result = await RequestService.deleteTestCase({
                'id': testCaseID,
            })
            console.log(result)
            this.getTestCases()
        }
    },
    data: () => ({
        testTitle: null
    })
}
</script>

<style scoped>

.whole {
  font-family: Nunito, Avenir, Helvetica, Arial, sans-serif;
  padding: 0 1.3rem 0 1.3rem;
}

h2 {
    font-size: 20px;
    color: #DF3D2A;
    font-weight: bold;
}

.category {
    display: inline;
}

.del-cat {
    font-size: 20px;
    color: rgb(30, 30, 30);
}

.card-cat {
    color: rgb(30, 30, 30);
}

.card-area {
    background-color: rgb(248, 248, 248);
    margin-bottom: 30px;
    margin-top: 10px;
    padding: 10px 15px;
    border-radius: 13px;
    color: rgb(70, 70, 70);
    font-family: Nunito;
    font-size: 20px;
}

h3 {
    font-size: 19px;
    font-weight: bold;
    color: rgb(30, 30, 30);
    margin: 4px 0 10px 0;
}

.single-test {
    margin: 4px 0;
    display: flow-root;
}

.test-description {
    float: left;
}

.float-right {
    float: right;
}

.radio-buttons {
    margin-left: 20px;
    cursor: pointer;
}

form {
    display: inline;
}

.trash {
    width: 20px;
    position: relative;
    top: -1px;
}

.trash-button {
    margin-left: 12px;
}

.red-button:hover {
    background-color: #E74C3C;
    border-color: #E74C3C;
}

.invisible-button {
    background: transparent;
    border: none !important;
}

.red-text {
    color: #E74C3C;
}

.fail-report {
    padding-bottom: 10px;
}

.add-test-button {
    width: 19px;
    position: relative;
    top: -2px;
}
</style>