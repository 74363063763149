<template>
  <div>
    <div class="row">
      <div
        class="col-sm-2 col-md"
        v-for="test in dashboardSectors" 
        :key="test.name"
      >
          <div 
            class="image-header bg-white"
            @click="setSelectedService(test.id)" 
            :style="checkSelectedServiceCircle(test.id)">
            <img
              :src="test.img"
              class="image w-100 img-fluid"
              :style="checkSelectedServiceImg(test.id)"
              :alt="test.name + ' logo'"
            />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QA",
  props: [
    'dashboardSectors',
    'selectedService'
  ],
  methods: {
    setSelectedService(id) {
      this.$emit('service-change', id)
    },
    checkSelectedServiceImg(id) {
      return id === this.selectedService.id ? 'filter: grayscale(0%)' : 'filter: grayscale(100%)'
    },
    checkSelectedServiceCircle(id) {
      return id === this.selectedService.id ? 'box-shadow: 0 0 12px ' + this.selectedService.glow : ''
    }
  },
};
</script>

<style>

.row {
  padding: 1.2rem 0 2rem 0;
}

.image {
  filter: grayscale(100%);
}

.image-header{
  border-radius: 50%;
  cursor: pointer;
}

</style>